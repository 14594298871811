import React, { useEffect, useState } from "react";
import Input from "../Input/Input";
import './MyCart.css';
import { useNavigate } from "react-router-dom";
import axios from "axios";

function SomeoneElse({ onGiftChange, onGiftDetailChange }) {
  const [isChecked, setIsChecked] = useState(false); // Checkbox for "Order for Someone Else"
  const [detail, setDetail] = useState({
    firstname: "",
    email: "",
    telephone: "",
    gift_message: "",
  });
  const [notificationPreferences, setNotificationPreferences] = useState({
    email: true,
    whatsapp: false,
    sms: false,
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev); // Toggle visibility
    onGiftChange(!isChecked);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const updatedDetail = { ...detail, [name]: value };

    setDetail(updatedDetail);
    onGiftDetailChange(updatedDetail);

    // Update local storage
    localStorage.setItem("giftDetails", JSON.stringify(updatedDetail));
  };

  const handleNotificationChange = (e) => {
    const { name, checked } = e.target;
    setNotificationPreferences((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const fetchOffers = async () => {
    setLoading(true);
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}user/profile`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 401) {
        navigate("/login");
      } else if (response.data.data) {
        const newOffers = response.data.data.userDetail;
        setDetail((prevDetail) => ({
          ...prevDetail,
          ...newOffers,
        }));
      } else {
        setErrorMessage("No data available.");
      }
    } catch (error) {
      setErrorMessage("Error fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, []);

  return (
    <>
      <div className="mb-4">
        <div className="container">
          <div className="row">
            <div className="p-0">
              <div className="card p-1">
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-row align-items-center ms-2">
                    <div className="icon">
                      <img src="https://media.istockphoto.com/id/1125279178/vector/mail-line-icon.jpg?s=612x612&w=0&k=20&c=NASq4hMg0b6UP9V0ru4kxL2-J114O3TaakI467Pzjzw=" alt="icon" />
                    </div>
                    <div className="d-flex gap-3 ">
                      <div className="ms-2 c-details text-break">
                        <h6 className="mb-0">{detail.firstname || detail.first_name}</h6>
                      </div>
                      <div className="ms-2 c-details text-break">
                        <h6 className="mb-0">{detail.email}</h6>
                      </div>
                      <div className="ms-2 c-details text-break">
                        <h6 className="mb-0">{detail.telephone || detail.mobile}</h6>
                      </div>
                    </div>

                    
                  </div>
                  
                </div>

                <div className="d-flex gap-4 ms-2">
                <div className="icon  gap-2 text-black ">
                      <img src="https://static.vecteezy.com/system/resources/thumbnails/002/206/240/small/fast-delivery-icon-free-vector.jpg" alt="icon" />
                 
                    </div>
               
            <div className="d-flex gap-2 text-black align-items-center">
              <input
                type="checkbox"
                name="email"
               
                checked={notificationPreferences.email}
                onChange={handleNotificationChange}
              />
              <label  className="text-black pt-1 ">Email</label>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <input
                type="checkbox"
                name="whatsapp"
                checked={notificationPreferences.whatsapp}
                onChange={handleNotificationChange}
              />
              <label  className="text-black pt-1">WhatsApp</label>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <input
                type="checkbox"
                name="sms"
                checked={notificationPreferences.sms}
                onChange={handleNotificationChange}
              />
              <label  className="text-black pt-1">SMS</label>
            </div>
          </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="someelse d-flex-center">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <p className="f-18 f-weight-600 text-o">ORDER FOR SOMEONE ELSE</p>
        </div>
      </div>

      <div className={`bg-fb p-0 someone-detail ${isChecked ? "show-detail" : "hide-detail"}`}>
        <Input
          placeholdertext="Enter recipient's First Name"
          labeltext="First Name"
          fortext="firstname"
          typetext="text"
          name="firstname"
          value={detail.firstname}
          onChange={handleInputChange}
        />
        <Input
          placeholdertext="Enter recipient's phone number"
          labeltext="Phone Number"
          fortext="telephone"
          typetext="tel"
          name="telephone"
          value={detail.telephone}
          onChange={handleInputChange}
        />
        <Input
          placeholdertext="Enter recipient's Email"
          labeltext="Email"
          fortext="email"
          typetext="email"
          name="email"
          value={detail.email}
          onChange={handleInputChange}
        />
        <Input
          placeholdertext="Enter a gift message"
          labeltext="Message"
          fortext="gift_message"
          typetext="text"
          name="gift_message"
          value={detail.gift_message}
          onChange={handleInputChange}
          maxLength={50}
        />
     
      </div>
    </>
  );
}

export default SomeoneElse;
