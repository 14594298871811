import React, { useState } from "react";

function CopyCouponCode({ couponCode }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(couponCode).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Revert back to default after 2 seconds
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <button className="common-btn" type="submit" onClick={handleCopy}>
        Copy
      </button>
      {isCopied && (
        <div
          style={{
            position: "absolute",
            top: "60px",
            left: "50%",
            transform: "translateX(-50%)", 
            backgroundColor: "#28a745", 
            color: "#fff", 
            padding: "10px 20px", 
            borderRadius: "8px", 
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", 
            fontSize: "16px", 
            zIndex: 1000, 
            whiteSpace: "nowrap", 
          }}
        >
          Coupon code copied!
        </div>
      )}
    </div>
  );
}

export default CopyCouponCode;
